const { default: backendIp } = require("./backendIp");

const serverDomain = `${backendIp}/graphql`; // Dallas

module.exports = {
  serverRequest(requestBody, token) {
    if (token) {
      const request = fetch(serverDomain, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          Accept: 'application/json',
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((resData) => resData)
        .catch((err) => {
          console.log(err);
        });
      return request;
    }
    const request = fetch(serverDomain, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: 'application/json',
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((resData) => resData)
      .catch((err) => {
        err.json();
        console.log(err);
      });
    return request;
  },
};