import React from 'react'
import X from "../Images/X.svg"
import Check from "../Images/Check.svg"

export default function ConfirmedModal(props) {
  const { name } = props
  setTimeout(() => {
    window.location.reload()
  }, 10000)
  return (
    <div className='singleProvider'>
      <img onClick={() => {window.location.reload()}} style={{margin: '0 0 7rem 30rem'}} src={X} alt="Cancel" />
      <div className='circle middle'>
        <img src={Check} alt="Check"/>
      </div>
      <div className="confirmedName">You're checked in.</div>
      <div className="confirmedName">{name} will be with you soon.</div>
    </div>
  )
}