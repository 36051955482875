import React, { useState } from 'react'
import X from "../Images/X.svg"
import { serverRequest } from '../Middleware/fetch'
import ConfirmedModal from './Confirmed'

export default function ConfirmModal(props) {
  const [confirmed, setConfirmed] = useState(false)
  const { img, name, id } = props

  const checkIn = () => {
    let hour = new Date().getHours()
    let mp = "AM"
    let minute = new Date().getMinutes()
    if (hour > 12) {
      hour -= 12
      mp = "PM"
    }
    if (minute < 10) {
      minute = `0${minute}`
    }
    const newTime = `${hour}:${minute} ${mp}`
    const requestBody = {
      query: `
        mutation {
          checkIn(id:"${id}", time: "${newTime}"){
            _id
            name
            phone
            imagepath
          }
        }
      `
    }
    serverRequest(requestBody)
    .then((resData) => {
      console.log(resData)
      setConfirmed(true)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <div>
      {!confirmed ? 
      <div className='singleProvider'>
        <img onClick={() => {window.location.reload()}} style={{margin: '0 0 7rem 30rem'}} src={X} alt="Cancel" />
        <div className='circle middle'>
          <img src={img} alt={name}/>
        </div>
        <div className="name">{name}</div>
        <button onClick={() => checkIn()} className='confirm'>Confirm</button>
        </div>
      : 
      <ConfirmedModal name={name}/>}
    </div>
  )
}