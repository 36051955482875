import React, { useEffect, useState } from "react"
import { serverRequest } from "../Middleware/fetch";
import ConfirmModal from "./Confirm";
import "./Provider.css"

export default function Providers() {
  const [selected, setSelected] = useState(null)
  const [providers, setProviders] = useState([
  {
    name: "Shawna",
    img: require("../Images/dice.jpg")
  },
  {
    name: "Taneshia",
    img: require("../Images/dice.jpg")
  },
  {
    name: "Ed",
    img: require("../Images/dice.jpg")
  },
  {
    name: "Katherine",
    img: require("../Images/dice.jpg")
  },
  {
    name: "Kahle",
    img: require("../Images/dice.jpg")
  },
  {
    name: "Jenn",
    img: require("../Images/dice.jpg")
  },
  {
    name: "Fake",
    img: require("../Images/dice.jpg")
  }
  ]);
  
  useEffect(() => {
    getProviders()
  }, [])

  const getProviders = () => {
    const requestBody = {
      query: `
        {
          getUsers {
            _id
            name
            phone
            imagepath
          }
        }
      `
    }
    serverRequest(requestBody)
    .then((resData) => {
      setProviders(resData.data.getUsers)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <div style={{display: "flex", justifyContent: "center"}}>
      {selected === null ? 
      <div className="providers">
        <h1>Please select your provider to check in.</h1>
        {providers.map((provider) => {
          return (
            <div key={provider._id} onClick={() => setSelected(provider)} className='provider'>
              <div className='circle'>
                <img src={provider.imagepath} alt={provider.name}/>
              </div>
              <div>{provider.name}</div>
            </div>
          )
        })}
      </div>
      :
      <ConfirmModal img={selected.imagepath} name={selected.name} id={selected._id}/>
      }

    </div>
  )
}