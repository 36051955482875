import './App.css';
import Providers from './Pages/Providers';

function App() {
  return (
    <div className="App">
      <Providers />
    </div>
  );
}

export default App;
